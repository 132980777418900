import { WebCheckInPage } from "../../../views/webCheckIn";
import { AppStandardButton } from "../../UI/AppButtons";
import React from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export function SuccessPage() {
  return (
    <section className="min-h-[80vh] box-border px-44  flex flex-col border-t-2 border-solid border-grey-300">
      <header className="grow flex flex-col items-center justif-center gap-4 box-border py-8">
        <img src="/assets/images/success-icon@2x.png" className="h-36 w-36" />
        <h1 className="text-3xl font-bold text-[#004188] py-2">
          {t("Congratulations! Your Web Check-In Is Completed")}
        </h1>
        <p className="font-bold text-black-600"></p>
        <AppStandardButton
          href="/booking/web-check-in"
          className={"w-fit py-4 "}
          color="primary-2"
          style={{ borderRadius: "5" }}
        >
          {/* Go to Account */}
          <span className="normal-case text-[#004188] p-1 text-lg">
            {" "}
            Homepage
          </span>
        </AppStandardButton>
      </header>
      <main></main>
      <footer className="text-gray-600 box-border pb-32">
        {/* Web Checkin Info */}
        {/* Web Checkin Information */}

        <div className="box-border border-2 border-solid border-grey-200 px-8"></div>
      </footer>
    </section>
  );
}
// export { WebCheckinConfirmation };
