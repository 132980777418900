import {
  DefaultValuesDetailsForm,
  IndividualFormProps,
} from "../../Layouts/WebCheckInForms/WebCheckInForms.props";
import {
  IndividualFormFields,
  ReadOnlyIfInitialValuesNotProvided,
  WebCheckInFormFields,
} from "../../Layouts/WebCheckInForms/FormOptions";

import { AppInputSwitchProps } from "../../Layouts/AppInputSwitcher/AppInputSwitcher.props";
import { AppInputSwitcher } from "../../Layouts/AppInputSwitcher/AppInputSwitcher";
import { FormFieldErrorMessage } from "../FormFieldErrorMessage/FormFieldErrorMessage";
import React from "react";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { SelfDriverFormFields } from "../../Layouts/WebCheckInForms/FormKeys";
import { useTranslation } from "react-i18next";
import { useWebCheckInForm } from "../../../hooks/WebCheckInForm_2";
import { AppStandardButton } from "../AppButtons";

export function IndividualUserForms({
  values = {},
  initialValues = { ...DefaultValuesDetailsForm },
  onSubmit = () => {},
  onChange = () => {},
  children = <></>,
}: IndividualFormProps) {
  const [
    {
      setValue,
      getValues,
      register,
      handleSubmit,
      formState: { errors },
    },
    individualFormFields,
  ] = useWebCheckInForm(
    IndividualFormFields,
    {
      ...Object.keys(values).reduce((prevValue, currentKey) => {
        if (!(currentKey in prevValue)) {
          // prevValue[currentKey] = prevValue[currentKey];
          prevValue[currentKey] = initialValues[currentKey];
        }
        return prevValue;
      }, values),
    },
    onChange
  );

  const handleCountryChange = (newCountryValue) => {
    setValue("country", newCountryValue || "");
    setValue("city", "");
    setValue("state", "");
  };

  const { t: translate } = useTranslation();
  return (
    <form
      onSubmit={handleSubmit((e) => {
        onSubmit(e);
      })}
    >
      <div className={"grid grid-cols-2 gap-6 mt-4"}>
        {Object.values(individualFormFields).map((el, index) => {
          const readOnly =
            el.readOnly ||
            (ReadOnlyIfInitialValuesNotProvided.includes(el.key) &&
              !!initialValues[el.key]);
          const options: RegisterOptions = {
            required: {
              value:
                !!el.required && !readOnly && initialValues[el.key] == null,
              message: "This is a required field",
            },
          };
          if (el.type === "email") {
            options.pattern = {
              value: /^[a-zA-Z\d+_.-]+@[a-zA-Z\d.-]+$/,
              message: "Please provide a valid email",
            };
          }
          return (
            <div key={index}>
              <AppInputSwitcher
                {...el}
                variant="material"
                key={index}
                required={false}
                {...register(el.key, {
                  ...options,
                })}
                readOnly={readOnly}
                value={getValues(el.key) || ""}
                label={translate(el.label || "")}
                placeholder={translate(el.placeholder || "")}
                className={"h-[4.3rem]"}
                onchange={(e) => {
                  if (el.key == "country") {
                    handleCountryChange(e);
                  }
                  setValue(el.key, e || "", {
                    // shouldValidate: true,
                  });
                }}
              />
              <FormFieldErrorMessage>
                {errors[el.key]?.message}
              </FormFieldErrorMessage>
            </div>
          );
        })}
      </div>
      <div>
        <div>
          {/* --- Is the signatory of the rental agreement also the main driver? (radio buttons and label)  --- */}
          <AppInputSwitcher
            {...WebCheckInFormFields.isSignatoryTheMainDriver} // tells us the type of this field
            required={false}
            {...register(WebCheckInFormFields.isSignatoryTheMainDriver.key)}
            value={
              getValues(WebCheckInFormFields.isSignatoryTheMainDriver.key) ||
              initialValues[
                WebCheckInFormFields.isSignatoryTheMainDriver.key
              ] ||
              values[WebCheckInFormFields.isSignatoryTheMainDriver.key] ||
              ""
            }
            label={translate(
              WebCheckInFormFields.isSignatoryTheMainDriver.label || ""
            )}
            placeholder={translate(
              WebCheckInFormFields.isSignatoryTheMainDriver.placeholder || ""
            )}
            className={"h-[4.3rem]"}
            onchange={(e) => {
              setValue(
                WebCheckInFormFields.isSignatoryTheMainDriver.key,
                e || "",
                {
                  shouldValidate: true,
                }
              );
            }}
          />
          {/*   ----------------------------------------------------------------   */}

          <FormFieldErrorMessage>
            {errors[WebCheckInFormFields.isSignatoryTheMainDriver.key]?.message}
          </FormFieldErrorMessage>
        </div>
        <div className={"flex flex-col gap-4"}>
          {getValues(WebCheckInFormFields.isSignatoryTheMainDriver.key) ===
            "YES" &&
            Object.values(SelfDriverFormFields).map(
              (el: AppInputSwitchProps, index: number) => {
                const readOnly =
                  el.readOnly ||
                  (ReadOnlyIfInitialValuesNotProvided.includes(el.key) &&
                    !!initialValues[el.key]);
                return (
                  <div key={index}>
                    <AppInputSwitcher
                      {...el}
                      {...register(el.key, {
                        required: {
                          value: !!el.required,
                          message: "This is a required field",
                        },
                      })}
                      required={false}
                      onChange={() => {}}
                      label={translate(el.label || "")}
                      readOnly={readOnly}
                      value={getValues(el.key) || ""}
                      key={el.key + index}
                      className={"h-[4.3rem]"}
                      onchange={(e: string) => {
                        setValue(el.key, e, {
                          shouldValidate: true,
                        });
                      }}
                    />
                    <FormFieldErrorMessage>
                      {errors[el.key]?.message}
                    </FormFieldErrorMessage>
                  </div>
                );
              }
            )}
        </div>
      </div>
      {children}
    </form>
  );
}
