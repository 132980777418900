import { AppInputSwitcher } from "../../components/Layouts/AppInputSwitcher/AppInputSwitcher";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AppCards from "../../components/UI/AppCards";
import React, { useState } from "react";
import BookingApi from "../../API/Apis/BookingApi";
import { useNavigate } from "react-router-dom";

export default function UpdatePassword() {
  const navigate = useNavigate();
  const [updateDetails, setUpdateDetails] = useState<any>([
    {
      email: "",
      verificationCode: "",
      newPassword: "",
    },
  ]);
  const isDesktopScreen = window.innerWidth >= 1310;
  return (
    <div className="flex flex-col items-center min-h-[60vh] justify-center">
      <form
        className="w-full md:w-1/2"
        onSubmit={(e) => {
          e.preventDefault();
          console.log(updateDetails);
          BookingApi.forgotPassword(updateDetails)
            .then((e) => {
              console.log(e.data);
              console.log(e.status);
              if (e.status == "success") {
                alert("Password Updated successfully");
                navigate("/signin");
              }

              if (e.status == "failed") {
                alert(e.errorMessage);
              }
              return e.data;
            })
            .catch((e) => {
              console.log(e);
            });
        }}
      >
        <AppCards className="box-border p-4">
          <header className="flex flex-col gap-2 box-border py-4">
            <h2 className="w-full  text-xl  font-bold">Change Password</h2>
          </header>
          <p className="mb-4">You can change your password here</p>
          <div className="mb-11">
            <Grid
              item
              xs={12}
              sm={6}
              className="mb-8"
              style={{ marginBottom: "15px" }}
            >
              <AppInputSwitcher
                value={updateDetails.verificationCode}
                className="w-full"
                type="text"
                containerwidth={!isDesktopScreen ? "360px" : "421px"}
                containerheight={"40px"}
                onchange={(v) => {
                  setUpdateDetails({ ...updateDetails, verificationCode: v });
                }}
                variant="material"
                required
                label="Verification Code"
                key="verificationCode"
                options={[]}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginBottom: "15px" }}>
              <AppInputSwitcher
                value={updateDetails.email}
                className="w-full"
                type="text"
                variant="material"
                required
                label="Email Address"
                key="email"
                onchange={(v) => {
                  setUpdateDetails({ ...updateDetails, email: v });
                }}
                options={[]}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginBottom: "15px" }}>
              <AppInputSwitcher
                value={updateDetails.newPassword}
                onchange={(v) => {
                  setUpdateDetails({ ...updateDetails, newPassword: v });
                }}
                //className="h-24"
                type="text"
                variant="material"
                required
                label="New Password"
                key="newPassword"
                options={[]}
              />
            </Grid>
            <div className="w-full  ">
              <Button
                type="submit"
                fullWidth
                // className="hover:drop-shadow-lg cursor-pointer"
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  bgcolor: "primary",
                  color: "#FFFFFF",
                  fontWeight: "bold",
                }}
              >
                Sign In
              </Button>
            </div>
          </div>
        </AppCards>
      </form>
    </div>
  );
}
