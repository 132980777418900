import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React from "react";
import { StandardButtonProps } from "./AppButton.props";
import { StyledProps } from "@mui/styles";
import UseButtonStyles from "./AppButtonStyles";

export default function AppButton({
  variant = "contained",
  rounded = false,
  loading = false,
  className = "",
  children = "",
  style = {},
  clickHandler = () => {},
  ...otherProps
}: StandardButtonProps) {
  const ButtonStyleClasses = UseButtonStyles({
    ...(style as StyledProps),
    rounded: rounded,
    fontWeight: "bold",
  });
  if (loading) {
    return (
      <LoadingButton
        // className={ButtonStyleClasses.standard}
        className="w-1/2 flex justify-end"
        variant="text"
        onClick={clickHandler}
        loading={loading}
        loadingPosition="center"
        {...otherProps}
        fullWidth={false}
      >
        {children}
      </LoadingButton>
    );
  }
  return (
    <div className={className}>
      <Button
        className={ButtonStyleClasses.standard}
        variant={variant}
        // border-color={"#f9ca01"}
        onClick={clickHandler}
        fullWidth
        {...otherProps}
      >
        {children}
      </Button>
    </div>
  );
}
