import { t } from "i18next";
import { AppInputSwitcher } from "../../components/Layouts/AppInputSwitcher/AppInputSwitcher";
import React, { useEffect, useState } from "react";
import BookingGetters from "../../store/onlineBooking/getters";
import { PaymentTypesDetails } from "../../components/Layouts/BookingForm/PaymentTypesDetails";
import { useTranslation } from "react-i18next";

function NewPersonalDetails({
  reservationSourcePayNow,
  reservationSourcePayLater,
  extrasSelected,
  updateVehiclePrice,
  updateSelectedPrice,
  updateUserPrimaryDetails,
  firstName,
  lastName,
  email,
  phoneNo,
  emailError,
  emailRef,
}) {
  const { t: translate } = useTranslation();
  const appSettings = BookingGetters.getAppSettings();
  const showOnePayMethodTwoPrice =
    appSettings && appSettings["showOnePayMethodTwoPrice"];

  const istabletScreen = window.innerWidth > 768 && window.innerWidth < 1510;

  const handleChange = (key, value) => {
    updateUserPrimaryDetails(key, value);
  };

  return (
    <>
      <div className="bg-white box-border p-8 justify-center">
        <h2 className="text-xl text-black pb-8 font-bold">
          {t("Personal Details")}
        </h2>
        <div
          className={`grid sm:grid-cols-2 ${
            istabletScreen ? "md:grid-cols-2" : "md:grid-cols-3"
          } gap-4`}
        >
          <AppInputSwitcher
            type="text"
            className={"h-20 sm:w-full box-border px-8"}
            required={true}
            label={t("First Name")}
            key="firstName"
            value={firstName || ""}
            onchange={(e) => handleChange("firstName", e.toString())}
          />
          <AppInputSwitcher
            type="text"
            required={true}
            className={"h-20 sm:w-full box-border px-8"}
            label={t("Last Name")}
            key="lastName"
            value={lastName || ""}
            onchange={(e) => handleChange("lastName", e.toString())}
          />
          <div className="w-full">
            <AppInputSwitcher
              type="email"
              required={true}
              className={"h-20 sm:w-full box-border px-8"}
              label={t("Email")}
              key="email"
              value={email || ""}
              onchange={(e) => handleChange("email", e.toString())}
              inputRef={emailRef}
            />
            {emailError && (
              <p
                style={{ color: "red", fontStyle: "italic" }}
                className="text-sm"
              >
                {emailError}
              </p>
            )}
          </div>
          <AppInputSwitcher
            type="mobile"
            required={true}
            className={"h-20 sm:w-full box-border px-8"}
            label={t("Mobile Number")}
            key="telephone"
            value={phoneNo || ""}
            onchange={(e) => handleChange("telephone", e.toString())}
          />
        </div>
      </div>
      {!showOnePayMethodTwoPrice && (
        <div className="bg-white box-border p-10">
          <PaymentTypesDetails
            reservationSourcePayNow={reservationSourcePayNow}
            reservationSourcePayLater={reservationSourcePayLater}
            extrasPrice={extrasSelected?.extrasPrice}
            updateVehiclePrice={updateVehiclePrice}
            updateSelectedPrice={updateSelectedPrice}
          />
        </div>
      )}
    </>
  );
}

export default NewPersonalDetails;
