import { useEffect, useState } from "react";
import { ChosenOptions } from "../BookingVehicleInfoCard/BookingVehicleInfoCard.props";
import React from "react";
import { SelectVehicleExtrasProps } from "./SelectVehicleExtras.props";
import { SelectedVehicleExtraOptions } from "../../../@types/VehicleOptions";
import VehicleExtraOptions from "../../UI/VehicleExtraOptions";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import theme from "../../../config/MuiColorThemes";
import { t } from "i18next";
import BookingGetters from "../../../store/onlineBooking/getters";
import VehicleExtrasWithInfo from "../../UI/VehicleExtraOptions/VehicleExtrasWithInfo";

SelectVehicleExtras.defaultProps = {
  onChange: () => {},
};
function SelectVehicleExtras({
  options,
  onChange,
  values = {},
  cardBackgroundColor = "",
  selectedCardBackgroundColor = "",
  cardTextColor = "",
  selectedCardTextColor = "",
  secondaryColor = "",
  fillToCard = false,
  insuranceList = {},
}: SelectVehicleExtrasProps): JSX.Element {
  const appSettings = BookingGetters.getAppSettings();
  const [selectedOptions, setSelectedOptions] =
    useState<ChosenOptions["options"]>(values);
  useEffect(() => {
    onChange(selectedOptions);
  }, [selectedOptions]);
  let key;
  return (
    <>
      <h2
        className="text-black text-xl font-semibold mb-2"
        style={{ gridColumn: "1 / -1" }}
      >
        {t("Add Extras")}
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 p-4 m-8 box-border rounded-md shadow-md">
        {options?.map((el, index) => {
          key = { index };
          const isElementInInsurance = insuranceList["Booking Insurance"].some(
            (e) => e.optionalsCode === el.optionName
          );
          if (!isElementInInsurance && appSettings?.showExtrasInfo) {
            return (
              <CardActionArea key={index}>
                <CardContent>
                  <VehicleExtrasWithInfo
                    onDecrementClick={() => {
                      setSelectedOptions((prev) => {
                        const optionName = el.optionName;
                        if (!prev[optionName]) {
                          return { ...prev };
                        }
                        const updatedOptions = { ...prev };
                        let quantity = updatedOptions[optionName].quantity;
                        quantity--;
                        updatedOptions[optionName] = {
                          ...updatedOptions[optionName],
                          quantity: Math.max(quantity, 0),
                          name: el.optionName,
                          details: el.details,
                          price: parseFloat(el.pricePerDayInUSD),
                        };
                        if (updatedOptions[optionName].quantity === 0) {
                          delete updatedOptions[optionName];
                        }
                        return updatedOptions;
                      });
                    }}
                    onIncrementClick={() => {
                      setSelectedOptions((prev) => {
                        let maxAllowed: number = 1;
                        const optionName = el.optionName;
                        if (el.maxAllowed) {
                          maxAllowed = el.maxAllowed;
                        }
                        const updatedOptions = { ...prev };
                        if (
                          !(
                            updatedOptions[optionName] &&
                            updatedOptions[optionName].quantity
                          )
                        ) {
                          updatedOptions[optionName] = {
                            ...updatedOptions[optionName],
                            quantity: 0,
                            name: optionName,
                            details: el.details,
                            price: parseFloat(options[index].pricePerDayInUSD),
                          };
                        }

                        let quantity = updatedOptions[optionName].quantity;
                        quantity++;
                        updatedOptions[optionName].quantity = Math.min(
                          quantity,
                          maxAllowed
                        );
                        return updatedOptions;
                      });
                    }}
                    {...el}
                    value={
                      selectedOptions[el.optionName]
                        ? selectedOptions[el.optionName].quantity || 0
                        : 0
                    }
                    cardBackgroundColor={cardBackgroundColor}
                    selectedCardBackgroundColor={selectedCardBackgroundColor}
                    cardTextColor={cardTextColor}
                    fillToCard={fillToCard}
                    selectedCardTextColor={selectedCardTextColor}
                    secondaryColor={secondaryColor}
                    maxAllowed={el.maxAllowed}
                    showOptionalCode={appSettings?.showOptionalCode}
                    showOptionalDescription={
                      appSettings?.showOptionalDescription
                    }
                    showDescFromBookingText={
                      appSettings?.showDescFromBookingText
                    }
                  />
                </CardContent>
              </CardActionArea>
            );
          } else if (!isElementInInsurance) {
            return (
              <CardActionArea key={index}>
                <CardContent>
                  <VehicleExtraOptions
                    onDecrementClick={() => {
                      setSelectedOptions((prev) => {
                        const optionName = el.optionName;
                        if (!prev[optionName]) {
                          return { ...prev };
                        }
                        const updatedOptions = { ...prev };
                        let quantity = updatedOptions[optionName].quantity;
                        quantity--;
                        updatedOptions[optionName] = {
                          ...updatedOptions[optionName],
                          quantity: Math.max(quantity, 0),
                          name: el.optionName,
                          details: el.details,
                          price: parseFloat(el.pricePerDayInUSD),
                        };
                        if (updatedOptions[optionName].quantity === 0) {
                          delete updatedOptions[optionName];
                        }
                        return updatedOptions;
                      });
                    }}
                    onIncrementClick={() => {
                      setSelectedOptions((prev) => {
                        let maxAllowed: number = 1;
                        const optionName = el.optionName;
                        if (el.maxAllowed) {
                          maxAllowed = el.maxAllowed;
                        }
                        const updatedOptions = { ...prev };
                        if (
                          !(
                            updatedOptions[optionName] &&
                            updatedOptions[optionName].quantity
                          )
                        ) {
                          updatedOptions[optionName] = {
                            ...updatedOptions[optionName],
                            quantity: 0,
                            name: optionName,
                            details: el.details,
                            price: parseFloat(options[index].pricePerDayInUSD),
                          };
                        }

                        let quantity = updatedOptions[optionName].quantity;
                        quantity++;
                        updatedOptions[optionName].quantity = Math.min(
                          quantity,
                          maxAllowed
                        );
                        return updatedOptions;
                      });
                    }}
                    {...el}
                    value={
                      selectedOptions[el.optionName]
                        ? selectedOptions[el.optionName].quantity || 0
                        : 0
                    }
                    cardBackgroundColor={cardBackgroundColor}
                    selectedCardBackgroundColor={selectedCardBackgroundColor}
                    cardTextColor={cardTextColor}
                    fillToCard={fillToCard}
                    selectedCardTextColor={selectedCardTextColor}
                    secondaryColor={secondaryColor}
                    showOptionalCode={appSettings?.showOptionalCode}
                    showOptionalDescription={
                      appSettings?.showOptionalDescription
                    }
                    showDescFromBookingText={
                      appSettings?.showDescFromBookingText
                    }
                  />
                </CardContent>
              </CardActionArea>
            );
          } else {
            return null;
          }
        })}
      </div>
    </>
  );
}
export { SelectVehicleExtras as default };
export { SelectVehicleExtras };
