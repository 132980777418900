import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { AppStandardButton } from "../components/UI/AppButtons";

export default function AlertMessage({
  openModal,
  requestOnClose,
  modalMessage,
}) {
  return (
    <Dialog
      open={openModal}
      onClose={requestOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ style: { borderRadius: "20px", minWidth: "400px" } }}
    >
      <DialogTitle style={{ padding: "2rem" }}>{modalMessage}</DialogTitle>

      <DialogActions>
        <AppStandardButton variant="outlined" onClick={requestOnClose}>
          <p className="text-black normal-case">Close</p>
        </AppStandardButton>
      </DialogActions>
    </Dialog>
  );
}
