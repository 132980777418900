import React, { memo } from "react";

function DefaultPage(props) {
  return (
    <section className="min-h-[80vh] box-border flex flex-col border-t-2 border-solid border-grey-300">
      <header className="grow flex flex-col items-center gap-4 box-border py-8">
        <h1 className="font-bold text-black">Create New Booking</h1>
        <p
          className="text-[#838181] text-[14px] max-w-[400px]"
          style={{ textAlign: "center" }}
        >
          {props.message}
        </p>
      </header>
      <footer className="text-gray-600 box-border pb-32">
        <div className="box-border border-2 border-solid border-grey-200 px-8"></div>
      </footer>
    </section>
  );
}
export default memo(DefaultPage);
