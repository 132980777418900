import React, { useEffect, useState } from "react";
import BookingDetalisListSideNavigation from "../../components/Layouts/ReservationsCard/BookingDetalisListSideNavigation";
import NotAuthorisedPage from "../notAuthorisedPage";
import ReservationCard from "../../components/Layouts/ReservationsCard/ReservationsCards";
import { t } from "i18next";
import { AppStandardButton } from "../../components/UI/AppButtons";
import BookingGetters from "../../store/onlineBooking/getters";

export default function MyReservedBookings() {
  const AppSettings = BookingGetters.getAppSettings();
  const [showReservedBooking] = useState(AppSettings?.reservedArea);
  return (
    <div>
      {showReservedBooking ? (
        <div className="flex">
          <div className="!w-780">
            <BookingDetalisListSideNavigation />
          </div>
          <div className="w-[75%] rounded-sm pt-12">
            <div className="text-[#333] px-8 max-w-screen-xl m-auto">
              <div className={"flex justify-between"}>
                <p className="text-[24px] font-medium leading-snug">
                  {t("Booking Details")}
                </p>
                <AppStandardButton
                  href="/"
                  className={"flex mr-1"}
                  variant="contained"
                  style={{
                    width: "150px",
                    // backgroundColor: "#CC3333",
                    borderRadius: "10px",
                  }}
                >
                  <span className="normal-case">Book a Car</span>
                </AppStandardButton>
              </div>
              <p className="text-[#838181] text-[16px]">
                {t("Here is your next reserve bookings.")}
              </p>
            </div>
            <div className={`p-4`}>
              <ReservationCard></ReservationCard>
            </div>
          </div>
        </div>
      ) : (
        <NotAuthorisedPage />
      )}
    </div>
  );
}
