import { t } from "i18next";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import { AppRadioButton } from "../../components/UI/AppRadioButtons/AppRadioButtons";
import {
  CompanyInvoiceFormFields,
  ReadOnlyIfInitialValuesNotProvided,
} from "../../components/Layouts/WebCheckInForms/FormOptions";
import { RegisterOptions } from "react-hook-form";
import { AppInputSwitcher } from "../../components/Layouts/AppInputSwitcher/AppInputSwitcher";
import { FormFieldErrorMessage } from "../../components/UI/FormFieldErrorMessage/FormFieldErrorMessage";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useWebCheckInForm } from "../../hooks/WebCheckInForm_2";
import {
  DefaultValuesDetailsForm,
  IndividualFormProps,
} from "../../components/Layouts/WebCheckInForms/WebCheckInForms.props";

function CompanyDetails(
  {
    checked,
    handleChange,
    userType,
    handleUserType,
    setCompanyinvoiceDetails,
    companyinvoiceDetails,
  },
  {
    onSubmit = () => {},
    values = {},
    initialValues = { ...DefaultValuesDetailsForm },
    onChange = () => {},
    children = <></>,
  }: IndividualFormProps
) {
  const [
    {
      setValue,
      getValues,
      register,
      handleSubmit,
      formState: { errors },
    },
    individualFormFields,
  ] = useWebCheckInForm(
    CompanyInvoiceFormFields,
    {
      ...Object.keys(values).reduce((prevValue, currentKey) => {
        prevValue[currentKey] =
          prevValue[currentKey] || initialValues[currentKey];
        return prevValue;
      }, values),
    },
    onChange
  );
  const { t: translate } = useTranslation();
  const [taxVal, setTaxVal] = useState("9999999999999999");
  const [isReadonly, setIsReadonly] = useState(true);
  const istabletScreen = window.innerWidth > 768 && window.innerWidth < 1300;
  const handleUserChange = (event) => {
    handleUserType(event);
  };
  const handleCheckboxChange = (event) => {
    handleChange(event);
  };
  return (
    <div className="bg-white box-border p-8">
      <h2 className="text-xl text-black pb-8 font-bold">
        {t("company_details")}
      </h2>
      <div className="flex flex-col">
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={handleCheckboxChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={t("Invoice to company")}
        />
        {checked ? (
          <>
            <AppRadioButton
              required={true}
              value={userType}
              onChange={(e) => {
                handleUserChange(e);
              }}
              options={[
                {
                  label: t("Individual"),
                  value: "Individual",
                },
                {
                  label: t("Company"),
                  value: "Company",
                },
              ]}
              color={"primary"}
            />
            <form
              onSubmit={handleSubmit((e) => {
                onSubmit(e);
              })}
            >
              <div
                className={`grid sm:grid-cols-2 ${
                  istabletScreen ? "md:grid-cols-2" : "md:grid-cols-3"
                } gap-4`}
              >
                {Object.values(individualFormFields).map((el, index) => {
                  let taxValue = "";
                  let readOnly;
                  el.readOnly ||
                    (ReadOnlyIfInitialValuesNotProvided.includes(el.key) &&
                      !!initialValues[el.key]);
                  const options: RegisterOptions = {
                    required: {
                      value: !!el.required && !readOnly,
                      message: "This is a required field",
                    },
                  };
                  if (el.type === "email") {
                    options.pattern = {
                      value: /^[a-zA-Z\d+_.-]+@[a-zA-Z\d.-]+$/,
                      message: "Please provide a valid email",
                    };
                  }
                  if (el.key === "taxCode") {
                    taxValue = taxVal;
                    readOnly = isReadonly;
                  } else {
                    taxValue = "";
                  }
                  return (
                    <>
                      {userType === "Company" &&
                      (el.key === "birthNation" ||
                        el.key === "birthPlace" ||
                        el.key === "dateOfBirth" ||
                        el.key === "birthProvince" ||
                        el.key === "taxCode" ||
                        el.key === "firstName" ||
                        el.key === "lastName" ||
                        el.key === "sex") ? (
                        ""
                      ) : (
                        <div key={index}>
                          <AppInputSwitcher
                            {...el}
                            key={index}
                            autocomplete={false}
                            required={false}
                            {...register(el.key, {
                              ...options,
                            })}
                            readOnly={readOnly}
                            value={
                              getValues(el.key) ||
                              initialValues[el.key] ||
                              values[el.key] ||
                              taxValue ||
                              ""
                            }
                            options={
                              el.key === "sex"
                                ? [translate("MALE"), translate("FEMALE")]
                                : el.options
                            }
                            label={translate(el.label || "")}
                            placeholder={translate(el.placeholder || "")}
                            className={"h-20 box-border px-8"}
                            onchange={(e) => {
                              const r: any = Object.keys(
                                CompanyInvoiceFormFields
                              ).at(index);
                              if (el.key == "birthNation" && e == "ITALIA") {
                                setTaxVal("");
                                setIsReadonly(false);
                              } else if (
                                el.key == "birthNation" &&
                                e != "ITALIA"
                              ) {
                                setTaxVal("9999999999999999");
                                setIsReadonly(true);
                              }
                              setCompanyinvoiceDetails({
                                ...companyinvoiceDetails,
                                [r]: e,
                              });

                              setValue(el.key, e || "", {
                                shouldValidate: true,
                              });
                            }}
                          />
                          <FormFieldErrorMessage>
                            {errors[el.key]?.message}
                          </FormFieldErrorMessage>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
              {children}
            </form>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
export default CompanyDetails;
