import * as React from "react";

import { AppRadioButton } from "../../components/UI/AppRadioButtons/AppRadioButtons";
import { FormFieldErrorMessage } from "../../components/UI/FormFieldErrorMessage/FormFieldErrorMessage";
import { SoleTraderFormFields } from "../../components/Layouts/WebCheckInForms/FormOptions";
import { useForm } from "react-hook-form";
import { useWebCheckInForm } from "../../hooks/WebCheckInForm";
import { Step, StepButton, StepLabel, Stepper } from "@mui/material";
import { AppStepper } from "../../components/UI/AppStepper/AppStepper";

export function TestPage(): JSX.Element {
  const [, , formFields] = useWebCheckInForm(SoleTraderFormFields);
  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<Record<string, any>>({
    mode: "onBlur",
    defaultValues: {
      company: "ABC",
    },
  });
  const [activeStep, setActiveStep] = React.useState(1);
  return (
    <>
      <AppStepper
        connector={<></>}
        activeStep={activeStep}
        allSteps={[
          {
            label: (
              <>
                <div className="flex flex-row">
                  <p>Hii</p>
                  <span>HEllo</span>
                </div>
                <div></div>
              </>
            ),
          },
        ]}
      />
      {activeStep > 0 && <p>HELLO</p>}
    </>
  );
}
