import HomePageConfigJson from "../../API/responses/BookingForm.json";
import FooterConfig from "../../API/responses/Footer.json";
import { PageConfigurations } from "./states";
import { SliceCaseReducers } from "@reduxjs/toolkit";

const PageConfigReducers: SliceCaseReducers<PageConfigurations> = {
  setHomePageConfig(state: PageConfigurations, { payload: config }) {
    state.homePage = {
      bookingFields: { ...HomePageConfigJson.fields },
      ...HomePageConfigJson,
      ...config,
    };
    state.bookingForm = {
      fields: [...HomePageConfigJson.fields],
      submitButtonColor: "green",
    };
  },
  setHomeBookingFormConfig(state: PageConfigurations) {
    state.bookingForm = {
      fields: HomePageConfigJson.fields,
      submitButtonColor: "red",
    };
  },
  setHeaderConfig(
    state: PageConfigurations,
    { payload: config }
  ): PageConfigurations {
    return {
      ...state,
      header: {
        ...state.header,
        logoUrl: "/assets/images/LogoFelirent_Generico.png", //For Autoone
        // logoUrl: "/assets/images/Myrent-logo.png", //For Myrent
        menuIcon: "/assets/icons/MenuIcon.svg",
        supportedLanguages: ["IT", "US"],
        isHidden: !!state.header.isHidden && true,
        ...config,
        // menuConfiguration: MenuOptions,
      },
    };
  },
  setFooterConfig(
    state: PageConfigurations,
    { payload: config }
  ): PageConfigurations {
    return {
      ...state,
      footer: {
        ...state.footer,
        logoUrl: "/assets/images/LogoFelirent_Generico.png",
        socialMediaLinks: {
          linkedIn: "https://www.linkedin.com/company/felirent-srl/",
          instagram: "https://www.instagram.com/felirent/",
          facebook: "https://www.facebook.com/Felirent/",
        },
        appStoreDetails: {
          android: "#",
          ios: "#",
        },
        styles: {},
        extras: {},
        isHidden: state.footer.isHidden && true,
        ...config,
      },
    };
  },
  setChooseVehicleConfig(state: PageConfigurations, { payload: config }) {
    state.chooseVehicle = {
      ...state.chooseVehicle,
      ...config,
    };
    state.bookingForm = {
      fields: [...HomePageConfigJson.fields],
      submitButtonColor: "green",
    };
  },
};
export { PageConfigReducers as default };
