import { t } from "i18next";
import { AppInputSwitcher } from "../../components/Layouts/AppInputSwitcher/AppInputSwitcher";
import React, { useEffect, useState } from "react";
import { useWebCheckInForm } from "../../hooks/WebCheckInForm_2";
import { CustomerDetailFormField } from "./UserDetailsForm";
import {
  DefaultValuesDetailsForm,
  DetailsFormValues,
} from "../../components/Layouts/WebCheckInForms/WebCheckInForms.props";
import { useTranslation } from "react-i18next";
import { BOOKING_ACTIONS } from "../../store/onlineBooking";
import { useDispatch } from "react-redux";
import BookingGetters from "../../store/onlineBooking/getters";
import { PaymentTypesDetails } from "../../components/Layouts/BookingForm/PaymentTypesDetails";

function PersonalDetails({
  reservationSourcePayNow,
  reservationSourcePayLater,
  extrasSelected,
  updateVehiclePrice,
  updateSelectedPrice,
}) {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState<DetailsFormValues>({
    ...DefaultValuesDetailsForm,
  });
  const [formStates, formFields] = useWebCheckInForm(
    CustomerDetailFormField,
    userDetails,
    (e) => {
      setUserDetails({ ...userDetails, ...e });
    }
  );
  const appSettings = BookingGetters.getAppSettings();
  const showOnePayMethodTwoPrice =
    appSettings && appSettings["showOnePayMethodTwoPrice"];
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(BOOKING_ACTIONS.setUserDetails(userDetails));
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [userDetails]);
  const handleCountryChange = () => {
    formStates.setValue("city", "");
    formStates.setValue("state", "");
  };
  const istabletScreen = window.innerWidth > 768 && window.innerWidth < 1300;
  return (
    <>
      <div className="bg-white box-border p-8 justify-center">
        <h2 className="text-xl text-black pb-8 font-bold">
          {t("Personal Details")}
        </h2>
        <div
          className={`grid sm:grid-cols-2 ${
            istabletScreen ? "md:grid-cols-2" : "md:grid-cols-3"
          } gap-4`}
        >
          {Object.values(formFields).map((el) => {
            if (el.key === "flightNumber" && !appSettings?.flightNumber) {
              return null;
            }
            return (
              <AppInputSwitcher
                {...el}
                key={el.key}
                label={translate(el.label || "")}
                className={"h-20 sm:w-full box-border px-8"}
                value={userDetails[el.key] || formStates.getValues(el.key)}
                options={
                  el.key === "sex"
                    ? [translate("MALE"), translate("FEMALE")]
                    : el.options
                }
                onchange={(e) => {
                  if (el.key == "country") {
                    handleCountryChange();
                  }
                  if (el.key == "dateOfBirth") {
                    const currentTime = new Date();
                    const dobDatePart = new Date(e.toString());
                    dobDatePart.setHours(currentTime.getHours());
                    dobDatePart.setMinutes(currentTime.getMinutes());
                    dobDatePart.setSeconds(currentTime.getSeconds());
                    dobDatePart.setMilliseconds(currentTime.getMilliseconds());
                    formStates.setValue(el.key, dobDatePart.toISOString(), {});
                  } else {
                    formStates.setValue(el.key, e.toString(), {});
                  }
                }}
              />
            );
          })}
        </div>
      </div>
      {!showOnePayMethodTwoPrice && (
        <div className="bg-white box-border p-10">
          {" "}
          <PaymentTypesDetails
            reservationSourcePayNow={reservationSourcePayNow}
            reservationSourcePayLater={reservationSourcePayLater}
            extrasPrice={extrasSelected?.extrasPrice}
            updateVehiclePrice={updateVehiclePrice}
            updateSelectedPrice={updateSelectedPrice}
          />
        </div>
      )}
    </>
  );
}
export default PersonalDetails;
