import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { ArrowDropDownOutlined } from "@mui/icons-material";
import { BookingApi } from "../../../API/Apis/BookingApi";
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import theme, {
  formatThemeForReservedArea,
} from "../../../config/MuiColorThemes";

function CollapsibleNavbar(params) {
  const navigateTo = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [userId] = useState(localStorage.getItem("userID"));
  const [userData, setUserData] = useState([]);
  const [showProfileSubMenu, setShowProfileSubMenu] = useState(false);
  const [dynamicTheme, setDynamicTheme] = useState<Theme | null>(null);
  useEffect(() => {
    async function fetchTheme() {
      const dynamicPalette = await formatThemeForReservedArea();
      const theme = createTheme(dynamicPalette);
      setDynamicTheme(theme);
    }
    fetchTheme();
  }, []);
  useEffect(() => {
    if (userId != null && userId != "null" && userId != "") {
      BookingApi.getUserProfileByUserId(parseInt(userId)).then((e) => {
        if (e.data.status == "Not Found") {
          alert(e.data.msg);
        } else {
          setUserData(e.data?.onlineUser[0]);
        }
      });
    }
  }, [userId]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleProfileSubMenu = () => {
    setShowProfileSubMenu(!showProfileSubMenu);
  };

  function handleBooking() {
    navigateTo("/myReservedBookings");
  }

  function handleLogout() {
    localStorage.setItem("isUserLoggedin", "false");
    localStorage.removeItem("userID");
    localStorage.removeItem("customerId");
    navigateTo("/signin");
  }

  function handleInvoices() {
    navigateTo("/myInvoices");
  }

  function handleCustomerDetails() {
    navigateTo("/customerDetailsList");
  }

  function handleCreateBooking() {
    navigateTo("/");
  }

  function handleProfile() {
    toggleProfileSubMenu();
  }

  function handlePersonalInfo() {
    navigateTo("/profile");
  }

  function handleChangePassword() {
    navigateTo("/changePassword");
  }

  return (
    <ThemeProvider theme={dynamicTheme || theme}>
      <div>
        <AppBar
          position="static"
          style={{
            width: "20%",
            backgroundColor: "transparent",
            boxShadow: "none",
            border: "none",
          }}
        >
          <Toolbar>
            <IconButton edge="start" aria-label="menu" onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
            <label
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "x-large",
              }}
            >
              {params.callFrom == "dashboard" && "Book Your Vehicle"}
            </label>
          </Toolbar>
        </AppBar>
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={toggleDrawer}
          PaperProps={{ style: { width: "300px" } }}
        >
          <List>
            <ListItem style={{ marginTop: "40px", marginBottom: "20px" }}>
              <Avatar
                alt={`${userData?.firstName}`}
                src="/assets/images/user.png"
              />
              <ListItemText
                primary={
                  <span
                    className={`text-[#000] font-poppins_regular text-[20px] ml-4`}
                  >
                    {userData?.firstName + " " + userData?.lastName}
                  </span>
                }
              />
            </ListItem>
            <ListItem
              button
              onClick={handleCreateBooking}
              style={{ marginTop: "20px" }}
            >
              <img
                className={`h-6`}
                src="/assets/images/createBooking.png"
                alt="CreateBooking"
              />
              <ListItemText
                style={{ marginLeft: "23px" }}
                primary={t("CreateBooking")}
              />
            </ListItem>
            <ListItem button onClick={handleCustomerDetails}>
              <img
                className="h-6"
                src="/assets/images/customer.details.png"
                alt="CustomerDetails"
              />
              <ListItemText
                style={{ marginLeft: "23px" }}
                primary={t("Customer Details")}
              />
            </ListItem>
            <ListItem button onClick={handleBooking}>
              <img
                className="h-6"
                src="/assets/images/bookingicon.png"
                alt="List of reservation"
              />
              <ListItemText
                style={{ marginLeft: "23px" }}
                primary={t("List of reservation")}
              />
            </ListItem>
            <ListItem button onClick={handleInvoices}>
              <img
                className="h-6"
                src="/assets/images/invoiceicon.png"
                alt="List of invoice"
              />
              <ListItemText
                style={{ marginLeft: "22px" }}
                primary={t("List of invoice")}
              />
            </ListItem>
            <ListItem button onClick={handleProfile}>
              <img
                className={`h-6`}
                src="/assets/images/profile.png"
                alt="My Profile"
              />
              <span
                style={{
                  marginLeft: "22px",
                  color: "primary",
                  fontWeight: "bold",
                }}
              >
                {t("My Profile")}
              </span>
              <ArrowDropDownOutlined
                style={{ marginLeft: "auto", cursor: "pointer" }}
                onClick={toggleProfileSubMenu}
              />
            </ListItem>
            {showProfileSubMenu && (
              <>
                <ListItem button onClick={handlePersonalInfo}>
                  <ListItemText
                    style={{ marginLeft: "45px" }}
                    primary={t("Personal information")}
                  />
                </ListItem>
                <ListItem button onClick={handleChangePassword}>
                  <ListItemText
                    style={{ marginLeft: "45px" }}
                    primary={t("Change Password")}
                  />
                </ListItem>
              </>
            )}
            <ListItem button onClick={handleLogout}>
              <img
                className="h-6"
                src="/assets/images/logout.png"
                alt="Logout"
              />
              <ListItemText
                style={{ marginLeft: "23px" }}
                primary={t("Logout")}
              />
            </ListItem>
          </List>
        </Drawer>
      </div>
    </ThemeProvider>
  );
}

export default CollapsibleNavbar;
