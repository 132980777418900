import {
  FormControl,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { MaterialInputProps, StandardInputProps } from "./AppTextField.props";
import React, { ChangeEvent, ForwardedRef, forwardRef, useState } from "react";
import {
  VerifiedRounded as VerifiedOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

import { UseInputStyles } from "./AppTextField.styles";

export const PasswordInputField = forwardRef(
  function PasswordInputFieldComponent(
    {
      label,
      className,
      required,
      onChange = () => {},
      value,
      readOnly = false,
    }: MaterialInputProps,
    ref: ForwardedRef<HTMLInputElement>
  ): JSX.Element {
    const InputStyle = UseInputStyles();
    const [showPassword, setShowPassword] = useState<boolean>(false);

    function handleClickShowPassword(): void {
      setShowPassword((prev) => !prev);
    }

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    return (
      <div className={"w-full h-full " + className}>
        <FormControl fullWidth className={InputStyle.password}>
          <InputLabel>{label}</InputLabel>
          <OutlinedInput
            ref={ref}
            disabled={readOnly}
            required={required}
            type={showPassword ? "text" : "password"}
            value={value || ""}
            onChange={(e) => {
              onChange(
                e.currentTarget.value,
                e as ChangeEvent<HTMLInputElement>
              );
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
    );
  }
);

export const MaterialInput = forwardRef(function MaterialInputComponent(
  {
    className = "",
    onChange = () => {},
    readOnly = false,
    variant = "standard",
    type = "text",
    value = "",
    ...props
  }: MaterialInputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  if (type === "password") {
    return (
      <div className={`h-full w-full ${className}`}>
        <PasswordInputField
          {...props}
          ref={ref}
          type={type}
          variant={variant}
          disabled={readOnly}
          onChange={(value, e) => {
            onChange((e.currentTarget as HTMLInputElement).value, e);
          }}
          fullWidth
        />
      </div>
    );
  }
  return (
    <div className={`h-full w-full ${className}`}>
      <TextField
        autoComplete={Date.now().toString()}
        ref={ref}
        {...props}
        label={props.label}
        value={value}
        type={type}
        // variant={
        //   (variant === "material" ? "outlined" : "standard") as
        //     | "outlined"
        //     | "standard"
        // } // switches between material and standard
        variant="outlined"
        disabled={readOnly}
        onChange={(e) => {
          // console.log(e);

          onChange(e.currentTarget.value, e as ChangeEvent<HTMLInputElement>);
        }}
        fullWidth
      />
    </div>
  );
});

export const StandardInput = forwardRef(function StandardInputComponent(
  {
    label,
    type,
    className,
    required,
    isVerified,
    onChange = () => {},
    placeholder,
    value,
    accept,
    readOnly = false,
    ...props
  }: StandardInputProps,
  ref: ForwardedRef<HTMLInputElement>
): JSX.Element {
  const InputStyle = UseInputStyles();
  return (
    <div className={"w-full h-full " + className}>
      <FormControl fullWidth className={InputStyle.standard}>
        {label && (
          <InputLabel shrink>
            {label}
            {required && <span className="text-red-600"> *</span>}
            {isVerified && (
              <VerifiedOutlined
                titleAccess="verified"
                sx={{ fontSize: "0.9rem" }}
                className="text-green-800"
              />
            )}
          </InputLabel>
        )}
        <InputBase
          ref={ref}
          disabled={readOnly}
          value={value || ""}
          onChange={(e) => {
            onChange(e.currentTarget.value, e);
          }}
          required={required}
          type={type}
          placeholder={placeholder}
          componentsProps={{ input: { accept } }}
          fullWidth
          {...props}
        />
      </FormControl>
    </div>
  );
});
