import { WebCheckInPage } from "../webCheckIn";
import { AppStandardButton } from "../../components/UI/AppButtons";
import React from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import BookingGetters from "../../store/onlineBooking/getters";
import { useNavigate } from "react-router-dom";

export function CancelledPage() {
  const appSettings = BookingGetters.getAppSettings();
  const redirectUrl = appSettings && appSettings["redirectUrl"];

  const hideIframe = () => {
    window.parent.postMessage(
      {
        event_id: "hideIframe",
      },
      "*"
    );
  };
  const navigateTo = useNavigate();
  const handleonclick = () => {
    if (redirectUrl != null && redirectUrl != "") {
      window.location.href = redirectUrl;
      hideIframe();
    } else {
      navigateTo("/embed");
    }
  };
  return (
    <section className="min-h-[80vh] box-border px-44  flex flex-col border-t-2 border-solid border-grey-300">
      <header className="grow flex flex-col items-center justif-center gap-4 box-border py-8">
        <img src="/assets/images/close.png" className="h-18 w-18" />
        <h1 className="text-3xl font-bold text-[#f84434] py-2 mt-4">
          {t("Sorry! Your Payment is Failed")}
        </h1>
        <p className="font-bold text-black-600"></p>
        <AppStandardButton
          onClick={handleonclick}
          className={"w-fit py-4 "}
          color="secondaryColor"
          style={{ borderRadius: "5" }}
        >
          <span className="normal-case text-[#ffffff] p-1 text-lg">
            {" "}
            Homepage
          </span>
        </AppStandardButton>
      </header>
      <main></main>
      <footer className="text-gray-600 box-border pb-32">
        <div className="box-border border-2 border-solid border-grey-200 px-8"></div>
      </footer>
    </section>
  );
}
