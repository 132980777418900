import { HeaderConfiguration } from "../../@types/Pages/PageConfiguration";

import AppFooter from "./AppFooter/AppFooter";
import AppHeader from "./AppHeader/AppHeader";
import { PageConfigGetters } from "../../store/PageConfigurations/getters";
import { BookingGetters } from "../../store/onlineBooking/getters";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import theme, { formatTheme } from "../../config/MuiColorThemes";

BasePageLayout.propTypes = {
  children: PropTypes.node,
  rootClasses: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  headerClasses: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  mainClasses: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  footerClasses: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};
export function BasePageLayout(props) {
  const dynamicPalette = formatTheme();
  const dynamicTheme = createTheme(dynamicPalette);
  const homePageConfig = BookingGetters.getAppSettings();
  const headerAndFooter = homePageConfig && homePageConfig["headerAndFooter"];
  const hideBackgroundImage =
    homePageConfig && homePageConfig["hideBackgroundImage"];
  const headerConfig: HeaderConfiguration =
    PageConfigGetters.getAppHeaderConfig();
  const appSettings = BookingGetters.getAppSettings();
  const [redirectEnable, setRedirectEnable] = useState<boolean | undefined>(
    false
  );
  const [redirecttoHomePage, setRedirectToHomePagve] = useState<
    string | null | undefined
  >("");
  useEffect(() => {
    setRedirectToHomePagve(appSettings?.redirectUrl);
    setRedirectEnable(appSettings?.redirectToHomePage);
    const logoUrl = appSettings && appSettings["config"]?.logoUrl;
    const companyName = appSettings && appSettings["companyName"];
    if (companyName != null) {
      document.title = companyName;
    }
    if (logoUrl != null) {
      const icon =
        document.querySelector('link[rel="icon"]') ||
        document.createElement("link");
      icon.href = logoUrl;
      icon.rel = "icon";
      document.head.appendChild(icon);
    }
  }, [appSettings]);
  useEffect(() => {
    if (
      redirectEnable &&
      redirecttoHomePage != null &&
      redirecttoHomePage != "" &&
      (window.location.pathname == "/" || window.location.pathname == "/embed")
    ) {
      window.location.href = redirecttoHomePage;
    }
  }, [redirecttoHomePage, redirectEnable]);
  const route = useLocation();
  return (
    <ThemeProvider theme={dynamicTheme}>
      <div
        className={`min-h-screen flex flex-col bg-cover  ${
          props.rootClasses || ""
        }`}
      >
        <div className={`text-white`}>
          {/*<header className={props.headerClasses}>*/}
          {/*  {!headerConfig.isHidden && headerAndFooter && (*/}
          {/*    <AppHeader dynamicTheme={dynamicTheme}></AppHeader>*/}
          {/*  )}*/}
          {/*</header>*/}
          <main
            className={`grow bg-cover min-h-screen  ${props.mainClasses}`}
            style={{
              backgroundImage:
                !hideBackgroundImage && route.pathname == "/"
                  ? `url(${
                      homePageConfig?.config?.backgroundImageUrl ||
                      " /assets/images/bg-common.jpg"
                    })`
                  : "",
            }}
          >
            {props.children}
          </main>
        </div>
        {/*<footer className={props.footerClasses}>*/}
        {/*  {!headerConfig.isHidden && headerAndFooter && <AppFooter></AppFooter>}*/}
        {/*</footer>*/}
      </div>
    </ThemeProvider>
  );
}
