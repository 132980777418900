import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import BookingApi from "../../../API/Apis/BookingApi";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import NotAuthorisedPage from "../../../views/notAuthorisedPage";
import { BookingGetters } from "../../../store/onlineBooking/getters";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import { t } from "i18next";
import { AppStandardButton } from "../../UI/AppButtons";
import { formatTheme } from "../../../config/MuiColorThemes";

export default function SignIn() {
  const navigate = useNavigate();
  const dynamicPalette = formatTheme();
  const dynamicTheme = createTheme(dynamicPalette);
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("email is invalid"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    if (data.remember) {
      localStorage.setItem("email", data.email);
      localStorage.setItem("password", data.password);
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }
    BookingApi.loginUser(data).then((data) => {
      if (data.data.status == "success") {
        localStorage.setItem("userID", data.data.user.id);
        localStorage.setItem("isUserLoggedin", "true");

        navigate("/");
        localStorage.setItem(
          "onlineUserType",
          data.data.onlineUserType.description
        );
      }
      if (data.data.status != "success") {
        console.log(data.data);
        setModalMessage(data.data.msg || "Password does not match");
        setOpenModal(true);
      }
    });
  };
  const appSettings = BookingGetters.getAppSettings();
  const [showReserve, setShowReserve] = useState<boolean>(
    !!appSettings?.reservedArea
  );
  useEffect(() => {
    BookingApi.getAppSetting().then((e: any) => {
      setShowReserve(e.reservedArea);
    });
  }, [showReserve]);
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const requestOnClose = () => {
    setOpenModal(false);
    setModalMessage("");
  };

  return (
    <ThemeProvider theme={dynamicTheme}>
      <div className="grid grid-cols-2">
        <div className="">
          <img
            src="/assets/images/reserve_area_car.jpg"
            className="w-fit h-fit aspect-square"
          />
        </div>
        <div className="flex flex-col top-0 items-center w-full h-full min-h-screen">
          {showReserve ? (
            <div className="w-[100%]">
              {" "}
              <Container component="main" maxWidth="xs">
                <Grid>
                  {" "}
                  <Box
                    sx={{
                      marginTop: 5,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      boxShadow: "none",
                    }}
                  >
                    <h1
                      className="text-3xl font-semibold mb-4"
                      style={{
                        color: dynamicTheme?.palette["secondaryColor"]?.main,
                      }}
                    >
                      {" "}
                      Sign In to your account !
                    </h1>
                    <Box
                      component="form"
                      onSubmit={handleSubmit(onSubmit)}
                      noValidate
                      sx={{
                        "& .MuiTextField-root": { mt: 2, width: "40ch" },
                      }}
                    >
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="email"
                          label={t("Email_Address")}
                          size="medium"
                          autoComplete="off"
                          autoFocus
                          {...register("email")}
                          error={!!errors.email}
                        />
                        <Typography variant="inherit" color="textSecondary">
                          {errors.email?.message}
                        </Typography>
                      </Grid>
                      <Grid>
                        {" "}
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          label={t("Password")}
                          type="password"
                          id="password"
                          size="medium"
                          autoComplete="off"
                          {...register("password")}
                          error={!!errors.password}
                        />
                        <Typography variant="inherit" color="textSecondary">
                          {errors.password?.message}
                        </Typography>
                      </Grid>

                      <FormControlLabel
                        className="text-black"
                        control={
                          <Checkbox
                            color={"primary"}
                            {...register("remember")}
                          />
                        }
                        label={t("Remember_me")}
                        sx={{ mb: 2 }}
                      />
                      <Grid>
                        {" "}
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{
                            mt: 3,
                            mb: 2,
                            color: "#FFFFFF",
                            fontWeight: "bold",
                            bgcolor: `${dynamicTheme?.palette?.["secondaryColor"]?.main} !important`,
                          }}
                        >
                          Sign In
                        </Button>
                      </Grid>

                      <Grid container style={{ marginTop: "5px" }}>
                        <Grid item xs>
                          <Link href="/forgotpassword" variant="body2">
                            <span className="text-[#004188]">
                              {t("Forgot_password")} ?
                            </span>
                          </Link>
                        </Grid>
                        <Grid item>
                          <Link href="/signup" variant="body2">
                            <span className="text-[#004188]">
                              {t("Register")}?
                            </span>
                          </Link>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Container>
            </div>
          ) : (
            <NotAuthorisedPage />
          )}
        </div>
        <Dialog
          open={openModal}
          onClose={requestOnClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{ style: { borderRadius: "20px" } }}
        >
          <DialogTitle style={{ padding: "3rem" }}>{modalMessage}</DialogTitle>

          <DialogActions>
            <AppStandardButton variant="outlined" onClick={requestOnClose}>
              <p className="text-black normal-case">Close</p>
            </AppStandardButton>
          </DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>
  );
}
