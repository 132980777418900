import { createTheme, Theme, ThemeOptions } from "@mui/material/styles";
import { MuiTextFieldVariantsOverridden } from "../modules/mui/TextFields";
import { BookingApi } from "../API/Apis/BookingApi";

export interface ExtraColors {
  white: true;
  blue: true;
  inputFieldBorder: true;
  gray: true;
  red: true;
  green: true;
  "success-2": true;
  "primary-2": true;
  clear: true;
  secondaryColor: true;
  ternaryColor: true;
}

let primaryVar = "#e6e6e6";
let secondaryVar = "#e6e6e6";
let ternaryVar = "#e6e6e6";
let backgroundVar = "#e6e6e6";
let primaryTextVar = "#000000";
let secondaryTextVar = "#000000";
let ternaryTextVar = "#000000";
let backgroundTextVar = "#e6e6e6";
let isThemeUpdated = "false";

const getDefaultPalette = () => ({
  secondaryColor: { main: secondaryVar, contrastText: secondaryTextVar },
  white: { main: "#ffffff" },
  primary: { main: primaryVar, contrastText: primaryTextVar },
  blue: { main: "#2563eb" },
  inputFieldBorder: { main: "#e6e6e6" },
  gray: { main: "#7a7c80" },
  red: { main: "#ff1717" },
  green: { main: "#1a7a67" },
  "success-2": { main: "#33B627" },
  "primary-2": { main: "#DBE7E9" },
  clear: { main: "transparent" },
  ternaryColor: { main: ternaryVar, contrastText: ternaryTextVar },
  backgroundColor: { main: backgroundVar, contrastText: backgroundTextVar },
});

const defaultPalette = getDefaultPalette();

let customTheme: ThemeOptions = {
  components: {
    MuiTextField: {
      variants: MuiTextFieldVariantsOverridden,
    },
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: "red",
        },
        root: {
          zIndex: "auto",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "red",
        },
        root: {
          zIndex: "auto",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "black",
        },
      },
    },
  },
  palette: defaultPalette,
};

export const formatTheme = () => {
  if (isThemeUpdated == "false") {
    isThemeUpdated = "true";
    BookingApi.fetchTheme().then((e) => {
      primaryVar = e.data.primaryColor;
      secondaryVar = e.data.secondaryColor;
      ternaryVar = e.data.ternaryColor;
      backgroundVar = e.data.backgroundColor;
      backgroundTextVar = e.data.backgroundTextColor;
      primaryTextVar = e.data.primaryTextColor;
      secondaryTextVar = e.data.secondaryTextColor;
      ternaryTextVar = e.data.ternaryTextColor;
      const dynamicTheme = updateTheme(
        primaryVar,
        secondaryVar,
        ternaryVar,
        backgroundVar,
        backgroundTextVar,
        primaryTextVar,
        secondaryTextVar,
        ternaryTextVar
      );
      return dynamicTheme;
    });
  }
  return updateTheme(
    primaryVar,
    secondaryVar,
    ternaryVar,
    backgroundVar,
    backgroundTextVar,
    primaryTextVar,
    secondaryTextVar,
    ternaryTextVar
  );
};

export const formatThemeForReservedArea = async () => {
  if (isThemeUpdated == "false") {
    isThemeUpdated = "true";
    await BookingApi.fetchTheme().then((e) => {
      primaryVar = e.data.primaryColor;
      secondaryVar = e.data.secondaryColor;
      ternaryVar = e.data.ternaryColor;
      backgroundVar = e.data.backgroundColor;
      backgroundTextVar = e.data.backgroundTextColor;
      primaryTextVar = e.data.primaryTextColor;
      secondaryTextVar = e.data.secondaryTextColor;
      ternaryTextVar = e.data.ternaryTextColor;
      const dynamicTheme = updateTheme(
        primaryVar,
        secondaryVar,
        ternaryVar,
        backgroundVar,
        backgroundTextVar,
        primaryTextVar,
        secondaryTextVar,
        ternaryTextVar
      );
      return dynamicTheme;
    });
  }
  return updateTheme(
    primaryVar,
    secondaryVar,
    ternaryVar,
    backgroundVar,
    backgroundTextVar,
    primaryTextVar,
    secondaryTextVar,
    ternaryTextVar
  );
};

const updateTheme = (
  bookingPrimaryColor,
  bookingSecondaryColor,
  bookingTernaryColor,
  bookingBackgroundColor,
  backgroundTextColor,
  primaryTextColor,
  secondaryTextColor,
  ternaryTextColor
) => {
  const updatedPalette = {
    ...getDefaultPalette(),
    primary: { main: bookingPrimaryColor, contrastText: primaryTextColor },
    secondaryColor: {
      main: bookingSecondaryColor,
      contrastText: secondaryTextColor,
    },
    ternaryColor: { main: bookingTernaryColor, contrastText: ternaryTextColor },
    backgroundColor: {
      main: bookingBackgroundColor,
      contrastText: backgroundTextColor,
    },
  };

  const updatedTheme: ThemeOptions = {
    components: {
      MuiTextField: {
        variants: MuiTextFieldVariantsOverridden,
      },
      MuiInputLabel: {
        styleOverrides: {
          asterisk: {
            color: "red",
          },
          root: {
            zIndex: "auto",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: "red",
          },
          root: {
            zIndex: "auto",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: "black",
          },
        },
      },
    },
    palette: updatedPalette,
  };
  customTheme = { ...customTheme, ...updatedTheme };
  return customTheme;
};

const theme: Theme = createTheme(customTheme);
export default theme;
