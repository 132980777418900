import React, { useEffect, useState } from "react";
import { BOOKING_ACTIONS } from "../../../store/onlineBooking";
import {
  AccountBalance,
  AccountBalanceWallet,
  RadioButtonCheckedTwoTone,
} from "@material-ui/icons";
import { useDispatch } from "react-redux";
import BookingGetters from "../../../store/onlineBooking/getters";
import { formatTheme } from "../../../config/MuiColorThemes";
import { createTheme } from "@mui/material/styles";
import { ChosenOptions } from "../BookingVehicleInfoCard/BookingVehicleInfoCard.props";
import { t } from "i18next";
function PaymentTypesDetails({
  reservationSourcePayNow,
  reservationSourcePayLater,
  extrasPrice,
  updateVehiclePrice,
  updateSelectedPrice,
}) {
  const [selectedBox, setSelectedBox] = useState<"payNow" | "payLater" | null>(
    "payNow"
  );
  const [reservationPayNow] = useState<string>(reservationSourcePayNow || "");
  const [reservationPayLater] = useState<string>(
    reservationSourcePayLater || ""
  );

  const dispatch = useDispatch();
  const dynamicPalette = formatTheme();
  const dynamicTheme = createTheme(dynamicPalette);
  const backgroundpaymentTextColor =
    dynamicTheme?.palette["backgroundColor"].contrastText;
  const selectedVehicle =
    BookingGetters.getSelectedVehicleDetails()?.vehicleDetails;
  const discount = (100 - (selectedVehicle?.Vehicle?.payNowDis || 0)) / 100;
  const [totalPrice] = useState<number>(
    parseFloat(
      selectedVehicle?.TotalCharge?.RateTotalAmount?.toString() || "0"
    ) * discount
  );
  const [selectedFields] = useState<ChosenOptions>({
    options: {},
    insurance: undefined,
  });
  const [payLaterPrice] = useState<number>(
    parseFloat(
      selectedVehicle?.TotalCharge?.RateTotalAmount?.toString() || "0"
    ) || 0
  );
  const [payNowDiscount, setPayNowDiscount] = useState("0");
  useEffect(() => {
    if (reservationPayNow == "true") {
      selectPayNow();
    }
  }, []);

  const selectPayNow = () => {
    setSelectedBox("payNow");

    if (selectedVehicle) {
      dispatch(
        BOOKING_ACTIONS.setSelectedVehicleDetails({
          paymentDetails: {
            addedExtraOptions: selectedFields.options,
            isDiscounted:
              (parseFloat(
                selectedVehicle?.TotalCharge?.RateTotalAmount?.toString()
              ) *
                parseFloat(selectedVehicle?.Vehicle?.payNowDis)) /
                100 || 0,
            price: totalPrice,
            selectedInsurance: selectedFields.insurance,
            type: "PAYNOW",
            isPayLaterFee: false,
            payLaterFeeAmt: "",
            extrasPrice: 0,
            extrasIncluded: false,
          },
          vehicleDetails: selectedVehicle,
        })
      );
      setTimeout(() => {}, 200);
    }
  };
  useEffect(() => {
    if (reservationPayLater == "true") {
      selectPayLater();
    }
  }, []);
  const selectPayLater = () => {
    setSelectedBox("payLater");

    if (selectedVehicle) {
      dispatch(
        BOOKING_ACTIONS.setSelectedVehicleDetails({
          paymentDetails: {
            addedExtraOptions: selectedFields?.options,
            isDiscounted: 0,
            price: payLaterPrice,
            selectedInsurance: selectedFields?.insurance,
            type: "PAYLATER",
            isPayLaterFee: false,
            payLaterFeeAmt: "",
            extrasPrice: 0,
            extrasIncluded: false,
          },
          vehicleDetails: selectedVehicle,
        })
      );
      setTimeout(() => {}, 200);
    }
  };

  useEffect(() => {
    if (payLaterPrice > 0) {
      const dis: number = ((payLaterPrice - totalPrice) / payLaterPrice) * 100;
      setPayNowDiscount(dis?.toFixed(0));
    }
  }, [totalPrice, payLaterPrice]);

  const [isDesktop] = useState(window.innerWidth >= 1300);
  useEffect(() => {
    if (selectedBox == "payNow") {
      updateVehiclePrice(totalPrice);
      updateSelectedPrice(
        (totalPrice + parseFloat(String(extrasPrice))).toFixed(2)
      );
    } else if (selectedBox == "payLater") {
      updateVehiclePrice(payLaterPrice);
      updateSelectedPrice(
        (payLaterPrice + parseFloat(String(extrasPrice))).toFixed(2)
      );
    }
  }, [selectedBox]);
  return (
    <div
      className={`flex ${
        isDesktop ? "flex-row" : "flex-col md:flex-row"
      } w-full h-full relative`}
    >
      <div
        className={`flex ${
          isDesktop
            ? "px-4 grow max-w-[30%]"
            : "justify-center px-4 py-4 mb-4 md:mb-0 md:mr-4"
        } text-gray-500 text-sm relative ${
          selectedBox == "payNow" ? "custom-shadow" : ""
        } border rounded-md`}
        style={
          reservationPayNow == "true" || reservationPayLater == "true"
            ? { pointerEvents: "none", opacity: 0.6 }
            : {}
        }
      >
        <div
          className={`flex-1 p-4 cursor-pointer`}
          onClick={() => {
            if (reservationPayNow === "false") {
              selectPayNow();
            }
          }}
        >
          <div className="flex items-center justify-center">
            <AccountBalanceWallet
              style={{ fontSize: "48px", color: "black" }}
            />
            <div>
              <h3
                className={`text-center text-xl font-bold`}
                style={{
                  marginLeft: "20px",
                  color: backgroundpaymentTextColor,
                }}
              >
                {t("Pay Now")}
              </h3>
              {payNowDiscount != "0" && (
                <h3
                  className={`text-center`}
                  style={{
                    marginLeft: "3px",
                    color: backgroundpaymentTextColor,
                  }}
                >
                  {t("Discount")} {payNowDiscount}%
                </h3>
              )}
            </div>
          </div>
          <div
            className="flex items-center justify-between"
            style={{
              marginLeft: isDesktop ? "27%" : "5%",
              marginTop: "20px",
              justifyContent: "space-between",
            }}
          >
            <p
              className={`text-center text-3xl`}
              style={{
                color: backgroundpaymentTextColor,
              }}
            >
              €{(totalPrice + parseFloat(String(extrasPrice))).toFixed(2)}
            </p>
            <RadioButtonCheckedTwoTone
              style={{
                color:
                  selectedBox === "payNow"
                    ? dynamicTheme?.palette["primary"]?.main
                    : dynamicTheme?.palette["secondaryColor"]?.main,
                fontSize: "xx-large",
              }}
            />
          </div>
        </div>
      </div>
      <div
        className={`flex ${
          isDesktop
            ? "px-4 grow max-w-[30%] md:ml-4"
            : "justify-center px-4 py-4 mb-4 md:mb-0 md:mr-4"
        } text-gray-500 text-sm relative ${
          selectedBox == "payLater" ? "custom-shadow" : ""
        } border rounded-md `}
        style={
          reservationPayNow == "true" || reservationPayLater == "true"
            ? { pointerEvents: "none", opacity: 0.6 }
            : {}
        }
      >
        <div
          className={`flex-1 p-4 cursor-pointer`}
          onClick={() => {
            if (reservationPayLater === "false") {
              selectPayLater();
            }
          }}
        >
          <div className="flex items-center justify-center">
            <AccountBalance style={{ fontSize: "48px", color: "black" }} />
            <h3
              className={`text-center text-xl font-bold`}
              style={{
                marginLeft: "20px",
                color: backgroundpaymentTextColor,
              }}
            >
              {t("Pay Later")}
            </h3>
          </div>
          <div
            className="flex items-center justify-between"
            style={{
              marginTop: "20px",
              marginLeft: isDesktop ? "25%" : "5%",
              justifyContent: "space-between",
            }}
          >
            <p
              className={`text-center text-3xl`}
              style={{
                color: backgroundpaymentTextColor,
              }}
            >
              €{(payLaterPrice + parseFloat(String(extrasPrice)))?.toFixed(2)}
            </p>
            <RadioButtonCheckedTwoTone
              style={{
                color:
                  selectedBox === "payLater"
                    ? dynamicTheme?.palette["primary"]?.main
                    : dynamicTheme?.palette["secondaryColor"]?.main,
                fontSize: "xx-large",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export { PaymentTypesDetails };
