import { Step, StepButton, Stepper, StepperProps } from "@mui/material";
import React from "react";
import { AppStepperProps } from "./AppStepperProps";

export function AppStepper({
  activeStep = 0,
  allSteps = [],
  ...props
}: AppStepperProps) {
  return (
    <Stepper {...(props as StepperProps)} activeStep={activeStep}>
      {allSteps.map((e, i) => {
        return (
          <Step {...e} key={i}>
            <StepButton icon={<></>} disableRipple>
              {e.label}
            </StepButton>
          </Step>
        );
      })}

      {activeStep >= 0 && allSteps[activeStep]?.content ? (
        allSteps[activeStep]?.content
      ) : (
        <></>
      )}
    </Stepper>
  );
}
