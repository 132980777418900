import { t } from "i18next";
import { AppRadioButton } from "../../components/UI/AppRadioButtons/AppRadioButtons";
import {
  CompanyInvoiceFormFields,
  ReadOnlyIfInitialValuesNotProvided,
} from "../../components/Layouts/WebCheckInForms/FormOptions";
import { RegisterOptions } from "react-hook-form";
import { AppInputSwitcher } from "../../components/Layouts/AppInputSwitcher/AppInputSwitcher";
import { FormFieldErrorMessage } from "../../components/UI/FormFieldErrorMessage/FormFieldErrorMessage";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useWebCheckInForm } from "../../hooks/WebCheckInForm_2";
import {
  DefaultValuesDetailsForm,
  IndividualFormProps,
} from "../../components/Layouts/WebCheckInForms/WebCheckInForms.props";
import PrivatePersonalDetails from "./PrivatePersonalDetails";

function NewCompanyDetails(
  { userType, handleUserType, setCompanyinvoiceDetails, companyinvoiceDetails },
  {
    onSubmit = () => {},
    values = {},
    initialValues = { ...DefaultValuesDetailsForm },
    onChange = () => {},
    children = <></>,
  }: IndividualFormProps
) {
  const [
    {
      setValue,
      getValues,
      register,
      handleSubmit,
      formState: { errors },
    },
    individualFormFields,
  ] = useWebCheckInForm(
    CompanyInvoiceFormFields,
    {
      ...Object.keys(values).reduce((prevValue, currentKey) => {
        prevValue[currentKey] =
          prevValue[currentKey] || initialValues[currentKey];
        return prevValue;
      }, values),
    },
    onChange
  );
  const { t: translate } = useTranslation();
  const [taxVal, setTaxVal] = useState("9999999999999999");
  const [isReadonly, setIsReadonly] = useState(true);
  const istabletScreen = window.innerWidth > 768 && window.innerWidth < 1300;
  const handleUserChange = (event) => {
    handleUserType(event);
  };
  // State for PrivatePersonalDetails
  const [privatePersonalDetails, setPrivatePersonalDetails] = useState({
    ...DefaultValuesDetailsForm,
  });

  const handleCountryChange = (newCountryValue) => {
    setValue("country", newCountryValue || "");
    setValue("city", "");
    setValue("state", "");
  };
  return (
    <div className="bg-white box-border p-8">
      <h2 className="text-xl text-black pb-8 font-bold">
        {t("company_details")}
      </h2>
      <div className="flex flex-col">
        <>
          <AppRadioButton
            required={true}
            value={userType}
            onChange={(e) => {
              handleUserChange(e);
            }}
            options={[
              {
                label: t("Private"),
                value: "Private",
              },
              {
                label: t("Company"),
                value: "Company",
              },
              {
                label: t("Individual"),
                value: "Individual",
              },
            ]}
            color={"primary"}
          />
          <div
            onSubmit={handleSubmit((e) => {
              onSubmit(e);
            })}
          >
            {userType === "Private" ? (
              <PrivatePersonalDetails
                userDetails={privatePersonalDetails}
                setUserDetails={setPrivatePersonalDetails}
              />
            ) : (
              <div
                className={`grid sm:grid-cols-2 ${
                  istabletScreen ? "md:grid-cols-2" : "md:grid-cols-3"
                } gap-4`}
              >
                {Object.values(individualFormFields).map((el, index) => {
                  let taxValue = "";
                  let readOnly;
                  el.readOnly ||
                    (ReadOnlyIfInitialValuesNotProvided.includes(el.key) &&
                      !!initialValues[el.key]);
                  const options: RegisterOptions = {
                    required: {
                      value: !!el.required && !readOnly,
                      message: t("This is a required field"),
                    },
                  };
                  if (el.type === "email") {
                    options.pattern = {
                      value: /^[a-zA-Z\d+_.-]+@[a-zA-Z\d.-]+$/,
                      message: "Please provide a valid email",
                    };
                  }
                  if (el.key === "taxCode") {
                    taxValue = taxVal;
                    readOnly = isReadonly;
                  } else {
                    taxValue = "";
                  }
                  return (
                    <>
                      {userType === "Company" &&
                      (el.key === "birthNation" ||
                        el.key === "birthPlace" ||
                        el.key === "dateOfBirth" ||
                        el.key === "birthProvince" ||
                        el.key === "taxCode" ||
                        el.key === "firstName" ||
                        el.key === "lastName" ||
                        el.key === "sex") ? (
                        ""
                      ) : (
                        <div key={index}>
                          <AppInputSwitcher
                            {...el}
                            key={index}
                            autocomplete={false}
                            required={false}
                            {...register(el.key, {
                              ...options,
                            })}
                            readOnly={readOnly}
                            value={
                              getValues(el.key) ||
                              initialValues[el.key] ||
                              values[el.key] ||
                              taxValue ||
                              ""
                            }
                            options={
                              el.key === "sex"
                                ? [translate("MALE"), translate("FEMALE")]
                                : el.options
                            }
                            label={translate(el.label || "")}
                            placeholder={translate(el.placeholder || "")}
                            className={"h-20 box-border px-8"}
                            onchange={(e) => {
                              const r = Object.keys(
                                CompanyInvoiceFormFields
                              ).at(index);
                              if (el.key === "birthNation" && e === "ITALIA") {
                                setTaxVal("");
                                setIsReadonly(false);
                              } else if (
                                el.key === "birthNation" &&
                                e !== "ITALIA"
                              ) {
                                setTaxVal("9999999999999999");
                                setIsReadonly(true);
                              }
                              setCompanyinvoiceDetails({
                                ...companyinvoiceDetails,
                                [r]: e,
                              });
                              if (el.key == "country") {
                                handleCountryChange(e);
                              }
                              setValue(el.key, e || "", {
                                // shouldValidate: true,
                              });
                            }}
                          />
                          <FormFieldErrorMessage>
                            {errors[el.key]?.message}
                          </FormFieldErrorMessage>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            )}
            {children}
          </div>
        </>
      </div>
    </div>
  );
}
export default NewCompanyDetails;
