import AppButton from "../UI/AppButtons/AppButton";
import AppCards from "../UI/AppCards";
import { AppInputSwitcher } from "./AppInputSwitcher/AppInputSwitcher";
import React from "react";
import { AppInputSwitchProps } from "./AppInputSwitcher/AppInputSwitcher.props";

const inputFields: Array<Array<AppInputSwitchProps>> = [
  [
    {
      label: "Nome",
      type: "text",
      required: true,
      key: "first_name",
      options: [],
    },
    {
      label: "E-mail Address",
      type: "email",
      required: true,
      key: "email",
      options: [],
    },
    {
      label: "Password",
      type: "password",
      key: "password",
      options: [],
    },
  ],
  [
    {
      label: "Secondo Nome",
      type: "text",
      required: true,
      key: "middle_name",
      options: [],
    },
    {
      label: "Telefono",
      type: "mobile",
      required: true,
      key: "mobile_number",
      options: [],
    },
  ],
  [
    {
      label: "Cognome",
      type: "text",
      required: true,
      key: "lastname",
      options: [],
    },
    {
      label: "Data Nascita",
      type: "date",
      required: true,
      variant: "separated",
      key: "dob",
      options: [],
    },
  ],
];

export default function AccountInformation() {
  return (
    <AppCards>
      <div className="border-box p-12">
        <h2 className="font-medium text-3xl font-bold box-border pb-12">
          Le mie informazioni
        </h2>
        <form
          className="flex flex-col"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="w-full h-full flex flex-row  gap-24">
            {inputFields.map((sections, index) => {
              return (
                <section className="w-1/3 grow" key={index}>
                  {sections.map((fields, index) => {
                    return (
                      <div key={index}>
                        <AppInputSwitcher
                          className="h-24"
                          label={fields.label}
                          type={fields.type}
                          variant={fields.variant || "standard"}
                          options={fields.options || []}
                          componentProps={{ ...fields }}
                          key={fields.key}
                        />
                      </div>
                    );
                  })}
                </section>
              );
            })}
          </div>
          <div className="w-full flex justify-center items-center">
            <AppButton type="submit" className="w-44">
              <p className="text-center w-full">Aggiorna</p>
            </AppButton>
          </div>
        </form>
      </div>
    </AppCards>
  );
}
