import { ConstructionOutlined, NavigateNextTwoTone } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import BookingApi from "../../API/Apis/BookingApi";
import { DefaultValuesCustomer } from "../../components/Layouts/WebCheckInForms/WebCheckInForms.props";
import theme from "../../config/MuiColorThemes";

export function UpdateCustomer() {
  const [selectedFile, setSelectedFile] = useState<any>();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const handleSubmission = () => {
    const formData = new FormData();

    formData.append("File", selectedFile);
  };
  const [selectedFile2, setSelectedFile2] = useState<any>();
  const [isFilePicked2, setIsFilePicked2] = useState(false);

  const changeHandler2 = (event) => {
    setSelectedFile2(event.target.files[0]);
    setIsFilePicked2(true);
  };
  // const UpdateApi = () => {
  //   const id: any = localStorage.getItem("userID");
  //   const cid: any = localStorage.getItem("customerId");
  //   console.log("heellloooo");

  //   // BookingApi.updateCustomerDetails(cid, customerDetails).then((e) => {
  //   //   console.log(e);
  //   //   alert("Updated Successfully");
  //   // });

  //   BookingApi.updateCustomerDetailByCustomerId(cid, customerDetails).then(
  //     (e) => {
  //       console.log(e);
  //       alert("Updated Successfully");
  //     }
  //   );
  // };

  const [phone, setPhone] = useState<any>([]);
  const [email, setEmail] = useState<any>([]);
  const [lastName, setLastName] = useState<any>([]);
  const [firstName, setFirstName] = useState<any>([]);
  const [street, setStreet] = useState<any>([]);
  const [postalCode, setPostalCode] = useState<any>([]);
  const [country, setCountry] = useState<any>([]);
  const [provience, setProvience] = useState<any>([]);
  const [customerDetails, setCustomerDetails] = useState<any>([]);
  const [city, setCity] = useState<any>([]);

  useEffect(() => {
    const id: any = localStorage.getItem("userID");
    setData();
  }, []);
  const setData = async () => {
    const id: any = localStorage.getItem("userID");
    const d = await BookingApi.getCustomerDeatilsByUserid(id);

    setCustomerDetails(d.data.customerList[0]);
    console.log(d.data.customerList[0]);
    setPhone(d.data.customerList[0].phoneNumb1);
    setEmail(d.data.customerList[0].email);
    setLastName(d.data.customerList[0].lastName);
    setFirstName(d.data.customerList[0].firstName);
    setStreet(d.data.customerList[0].city);
    setCountry(d.data.customerList[0].country);
    setPostalCode(d.data.customerList[0].postalCode);
    setProvience(d.data.customerList[0].provience);
    setCity(d.data.customerList[0].city);
  };
  const handleSubmit = (event) => {
    console.log("updated");
    // BookingApi.getCustomerDeatilsByUserid(88).then((e) => {
    //   console.log(e);
    // });
    event.preventDefault();
    console.log(event.target.value);
    const id: any = localStorage.getItem("userID");
    const cid: any = localStorage.getItem("customerId");
    console.log(customerDetails);
    BookingApi.updateCustomerDetailByCustomerId(id, cid, customerDetails)
      .then((e) => {
        console.log(e);
        alert("Updated Successfullu");
      })
      .catch((e) => {
        alert(e);
      });
    alert("dddddddddd");

    console.log("heellloooo");
  };

  return (
    <div>
      <form>
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                Customer Detail
              </Typography>

              <Box
                component="form"
                noValidate
                // onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      fullWidth
                      id="firstName"
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      value={firstName || ""}
                      label="First Name"
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      value={lastName || ""}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                      name="lastName"
                      autoComplete="family-name"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="email"
                      label="Email Address"
                      value={email || ""}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      name="email"
                      autoComplete="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      value={phone || ""}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                      autoComplete="Phone Number"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="street"
                      type="street"
                      value={street || ""}
                      onChange={(e) => {
                        setStreet(e.target.value);
                      }}
                      name="street"
                      id="street"
                      autoComplete="street"
                    ></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="country"
                      type="country"
                      name="country"
                      value={country || ""}
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="provience"
                      label="provience"
                      value={provience || ""}
                      onChange={(e) => {
                        setProvience(e.target.value);
                      }}
                      name="provience"
                      autoComplete="provience"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="city"
                      label="city"
                      value={city || ""}
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                      name="city"
                      autoComplete="city"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      value={postalCode || " "}
                      onChange={(e) => {
                        setPostalCode(e.target.value);
                      }}
                      autoComplete="postalCode"
                      name="postalCode"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="taxCode"
                      label="TaxCode"
                      value={customerDetails[0]?.taxCode ?? ""}
                      autoComplete="TaxCode"
                      name="TaxCode"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="date"
                      label="Birthday"
                      fullWidth
                      type="date"
                      name="birthDate"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max:
                          new Date().getFullYear() -
                          18 +
                          "-" +
                          new Date().getMonth() +
                          "-" +
                          new Date().getDay().toString().padStart(2, "0"),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="birthNation"
                      value={customerDetails[0]?.birthNation ?? ""}
                      label="birth "
                      autoComplete="birthDate"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <input
                      type="text"
                      name="name"
                      defaultValue={customerDetails[0]?.birthPlace ?? ""}
                      placeholder="Name"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="birthProvince"
                      value={customerDetails[0]?.birthProv ?? ""}
                      label="birth Province"
                      autoComplete="birthProvince"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="document"
                      value={customerDetails[0]?.document ?? ""}
                      label="Document"
                      autoComplete="document"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="documentNumber"
                      value={customerDetails[0]?.documentNumb ?? ""}
                      label="Document Number"
                      autoComplete="documentNumber"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="documentType"
                      value={customerDetails[0]?.documentType ?? ""}
                      label="Document Type"
                      autoComplete="documentType"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="issuedBy"
                      value={customerDetails[0]?.issuedBy ?? ""}
                      label="Issued By "
                      autoComplete="issuedBy"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="releaseDate"
                      value={customerDetails[0]?.releaseDate ?? ""}
                      label="Release Date"
                      autoComplete="releaseDate"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="documentType"
                      value={customerDetails[0]?.releaseDate ?? ""}
                      label="Document Type"
                      autoComplete="documentType"
                    />
                  </Grid>
                </Grid>
                {/* <div className="grid grid-rows-2 grid-flow-col gap-4  mt-5">
                  <input type="file" name="file" onChange={changeHandler} />
                  {isFilePicked ? (
                    <div>
                      <p className="mb-3 font-light text-gray-500 dark:text-gray-400">
                        Filename: {selectedFile2.name}
                      </p>
                      <p className="mb-3 font-light text-gray-500 dark:text-gray-400">
                        Filetype: {selectedFile2.type}
                      </p>
                    </div>
                  ) : (
                    <p className="mb-3 font-light text-gray-500 dark:text-gray-400">
                      ID Card
                    </p>
                  )}

                  <input type="file" name="file" onChange={changeHandler2} />
                  {isFilePicked ? (
                    <div>
                      <p>Filename: {selectedFile.name}</p>
                      <p>Filetype: {selectedFile.type}</p>
                    </div>
                  ) : (
                    <p className="mb-3 font-light text-gray-500 dark:text-gray-400">
                      Driver License
                    </p>
                  )}
                </div> */}

                <Button
                  type="submit"
                  fullWidth
                  // onClick={UpdateApi}
                  onClick={() => {
                    handleSubmit(event);
                    alert("clicked");
                  }}
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    bgcolor: "#f9ca01",
                    color: "#075985",
                    fontWeight: "bold",
                    "&:hover": {
                      background: "#eab308",
                    },
                  }}
                  // onClick={handleSubmit(onSubmit)}
                >
                  UPDATE
                </Button>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </form>
    </div>
  );
}
